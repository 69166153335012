@import url('https://fonts.googleapis.com/css2?family=Changa+One&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
}

h1, h2, h3, h4, h5, h6, ul > li > a {
  font-family: 'Changa One', cursive;
}

.App {
  width: 100%;
}