nav  {
    display: flex;
    justify-content: space-between;
}

header nav ul {
    display: flex;
    list-style: none;
    gap: 15px;
    margin-right: 30px;
}

nav {
    position: relative;;
}

header nav ul li a {
    text-decoration: none;
    font-weight: 700;
    font-size: 1.3rem;
    color: black;
}

header nav ul {
    margin-right: 30px;
}

header nav h1, 
header nav ul li a,
.maintext h2,
.maintext p {
    color: white;
}

header nav h1, header nav ul li {
    margin-top: 30px;
}

header {
    background-image: url('../Assets/mainimg.jpg');
    min-height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

header nav h1 {
    margin-left: 30px;
}

.maintext {
    position: relative;
    left: 100px;
    top: 120px;
    font-size: 2rem;
    width: 50%;
    margin: 0;
    height: 50%;
}

header::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 700px;
    background: rgba(0, 0, 0, 0.5);
}

.maintext h2 {
    width: 70%;
}

.maintext p {
    width: 70%;
    margin-top: 30px;
    font-size: 20px;
}

/*Button*/

button {
    width: 200px;
    height: 45px;
    border-radius: 50px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    border: none;
    /*background-color: #de7021;*/
    background-color: #FCCF00;;
    color: #000;
    cursor: pointer;    
}

/* Media Queries */

@media screen and (max-width: 860px) {
    .maintext h2 {
        width: 100%;
    }
    
    .maintext p {
        width: 100%;
        margin-top: 30px;
        font-size: 15px;
    }

    header nav h1 {
        display: none;
    }

    header nav {
        justify-content: center;

    }

    header nav ul li {
        margin-right: 0px;
    }

    header nav ul {
        gap: 12px;
        margin-right: 0px;
    }

    div.card {
        margin-right: 0px;
    }
    
}

@media screen and (max-width: 520px) {
    .maintext h2 {
        width: 100%;
        font-size: 1em;
        text-align: center;
    }
    
    .maintext p {
        width: 100%;
        margin-top: 30px;
        font-size: 12px;
        text-align: center;
    }

    header nav h1 {
        display: none;
    }

    header nav {
        justify-content: center;

    }

    header nav ul li {
        margin-right: 0px;
    }

    header nav ul {
        gap: 12px;
        margin-right: 0px;
    }

    div.card {
        margin-right: 0px;
    }

    .maintext {
        left: 50%;
        transform: translateX(-50%);
    }
}