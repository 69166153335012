.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.card {
    width: 320px;
    height: 240px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    flex-wrap: wrap;
}


.icons {
    width: 50px;
    height: 50px;
}

.cardInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
}

.cardInfo p {
    width: 300px;
}

.cardInfo .cardText {
    font-weight: 700;
}

.cardInfo .cardText,.cardInfo .cardTextInfo {
    text-align: center;
}

@media screen and (max-width: 860px) {
    .cardContainer {
        justify-content: center;
    }
}