@import url(https://fonts.googleapis.com/css2?family=Changa+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
}

h1, h2, h3, h4, h5, h6, ul > li > a {
  font-family: 'Changa One', cursive;
}

.App {
  width: 100%;
}
nav  {
    display: flex;
    justify-content: space-between;
}

header nav ul {
    display: flex;
    list-style: none;
    grid-gap: 15px;
    gap: 15px;
    margin-right: 30px;
}

nav {
    position: relative;;
}

header nav ul li a {
    text-decoration: none;
    font-weight: 700;
    font-size: 1.3rem;
    color: black;
}

header nav ul {
    margin-right: 30px;
}

header nav h1, 
header nav ul li a,
.maintext h2,
.maintext p {
    color: white;
}

header nav h1, header nav ul li {
    margin-top: 30px;
}

header {
    background-image: url(../../static/media/mainimg.ad22a7df.jpg);
    min-height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

header nav h1 {
    margin-left: 30px;
}

.maintext {
    position: relative;
    left: 100px;
    top: 120px;
    font-size: 2rem;
    width: 50%;
    margin: 0;
    height: 50%;
}

header::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 700px;
    background: rgba(0, 0, 0, 0.5);
}

.maintext h2 {
    width: 70%;
}

.maintext p {
    width: 70%;
    margin-top: 30px;
    font-size: 20px;
}

/*Button*/

button {
    width: 200px;
    height: 45px;
    border-radius: 50px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    border: none;
    /*background-color: #de7021;*/
    background-color: #FCCF00;;
    color: #000;
    cursor: pointer;    
}

/* Media Queries */

@media screen and (max-width: 860px) {
    .maintext h2 {
        width: 100%;
    }
    
    .maintext p {
        width: 100%;
        margin-top: 30px;
        font-size: 15px;
    }

    header nav h1 {
        display: none;
    }

    header nav {
        justify-content: center;

    }

    header nav ul li {
        margin-right: 0px;
    }

    header nav ul {
        grid-gap: 12px;
        gap: 12px;
        margin-right: 0px;
    }

    div.card {
        margin-right: 0px;
    }
    
}

@media screen and (max-width: 520px) {
    .maintext h2 {
        width: 100%;
        font-size: 1em;
        text-align: center;
    }
    
    .maintext p {
        width: 100%;
        margin-top: 30px;
        font-size: 12px;
        text-align: center;
    }

    header nav h1 {
        display: none;
    }

    header nav {
        justify-content: center;

    }

    header nav ul li {
        margin-right: 0px;
    }

    header nav ul {
        grid-gap: 12px;
        gap: 12px;
        margin-right: 0px;
    }

    div.card {
        margin-right: 0px;
    }

    .maintext {
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
}
footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    grid-gap: 15px;
    gap: 15px;
    background-color: #2D394C;
}

footer  h1, footer h3 {
    color: #fff;
}

.headingFooter {
    color: #FCCF00;
}


@media screen and (max-width: 520px) {
    .headingFooter, .centerCityName {
        text-align: center;
    }
}
.content {
    min-height: 750px;
    width: 100%;
    background-color: rgb(247, 247, 247);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
}

/* Mission Section */

.missionSection {
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.missionSection .wrapper {
    width: 90%;
    height: 25%;
}

.missionSection > .wrapper > p {
    margin-top: 10px;
    line-height: 23px;
}

.imgWrapper {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hspImages {
    width: 200px;
    height: 200px;
    grid-gap: 50px;
    gap: 50px;
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 5px;
}

.headerWrapper {
    width: 50%;
}


/* Media Queries */

@media screen and (max-width: 860px) {
    .missionSection {
        min-height: 1500px;
    }

    .missionSection .wrapper {
        text-align: center;
        margin: 20px;
    }

    .missionSection .imgWrapper, .missionSection .wrapper {
        margin: 20px;
    }

    .cardWrapper > h1 {
       text-align: center;
       margin-top: 20px;
    }

    .headerWrapper {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}

@media screen and (max-width: 520px) {

    .missionSection .wrapper {
        text-align: center;
    }

    .missionSection .imgWrapper, .missionSection .wrapper {
        margin: 20px;
    }

    .headerWrapper {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}
.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.card {
    width: 320px;
    height: 240px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    flex-wrap: wrap;
}


.icons {
    width: 50px;
    height: 50px;
}

.cardInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
}

.cardInfo p {
    width: 300px;
}

.cardInfo .cardText {
    font-weight: 700;
}

.cardInfo .cardText,.cardInfo .cardTextInfo {
    text-align: center;
}

@media screen and (max-width: 860px) {
    .cardContainer {
        justify-content: center;
    }
}
