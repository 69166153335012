.content {
    min-height: 750px;
    width: 100%;
    background-color: rgb(247, 247, 247);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
}

/* Mission Section */

.missionSection {
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.missionSection .wrapper {
    width: 90%;
    height: 25%;
}

.missionSection > .wrapper > p {
    margin-top: 10px;
    line-height: 23px;
}

.imgWrapper {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hspImages {
    width: 200px;
    height: 200px;
    gap: 50px;
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 5px;
}

.headerWrapper {
    width: 50%;
}


/* Media Queries */

@media screen and (max-width: 860px) {
    .missionSection {
        min-height: 1500px;
    }

    .missionSection .wrapper {
        text-align: center;
        margin: 20px;
    }

    .missionSection .imgWrapper, .missionSection .wrapper {
        margin: 20px;
    }

    .cardWrapper > h1 {
       text-align: center;
       margin-top: 20px;
    }

    .headerWrapper {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}

@media screen and (max-width: 520px) {

    .missionSection .wrapper {
        text-align: center;
    }

    .missionSection .imgWrapper, .missionSection .wrapper {
        margin: 20px;
    }

    .headerWrapper {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}