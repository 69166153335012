footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    gap: 15px;
    background-color: #2D394C;
}

footer  h1, footer h3 {
    color: #fff;
}

.headingFooter {
    color: #FCCF00;
}


@media screen and (max-width: 520px) {
    .headingFooter, .centerCityName {
        text-align: center;
    }
}